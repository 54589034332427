export interface Tool {
  toolName: string;
  isFavorite: boolean;
  toolDescription: string;
  id: number;
}

const messages: Tool[] = [
  {
    toolName: 'Tollerances',
    isFavorite: true,
    toolDescription: 'Use this to calculate tolerances',
    id: 0
  },
  {
    toolName: 'Triangles',
    isFavorite: true,
    toolDescription: 'Use this to calculate geometric triangles',
    id: 2
  }
 
];

export const getMessages = () => messages;

export const getMessage = (id: number) => messages.find(m => m.id === id);
