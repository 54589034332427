import { useState } from 'react';
import { Tool, getMessages } from '../data/messages';
import { pin, walk, warning, wifi, wine, ellipse, ellipseOutline } from 'ionicons/icons';

import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonText,
    useIonViewWillEnter
} from '@ionic/react';
import './CalcTollerance.css';

const Tollerance: React.FC = () => {

    const [messages, setMessages] = useState<Tool[]>([]);
    const [shaftHole, setShaftHole] = useState<string>("holes");

    useIonViewWillEnter(() => {
        const msgs = getMessages();
        setMessages(msgs);
    });

    


    console.log(messages);
    return (
        <div>

            <IonCard>
                <IonCardHeader>
                    <IonSegment value={shaftHole} onIonChange={e => setShaftHole( e.detail.value??"")}>
                        <IonSegmentButton value="holes" layout="icon-start">
                            <IonIcon icon={ellipseOutline}></IonIcon>
                            <IonLabel>Hole</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="shafts" layout='icon-start'>
                            <IonIcon icon={ellipse}></IonIcon>
                            <IonLabel>Shaft</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonCardHeader>
                <IonCardContent >
                    <IonItem>
                        <IonLabel>
                            ISO
                        </IonLabel>
                        <IonSelect value={"brown"} interface="popover" onIonChange={e => console.log(e.detail.value)}>
                            {toleranceData[shaftHole].map((s:any) => (
                                <IonSelectOption key={s.standard} value={s.standard}>{s.standard}</IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonItem>

                    <IonItem>
                        <IonLabel position='stacked'>Nominal Size</IonLabel>
                        <IonInput type="number" inputMode='numeric' value={200} onIonChange={e => console.log(parseInt(e.detail.value!, 10))}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonText slot="start" color="secondary">
                            <h1>Tollerance:</h1>
                        </IonText>
                        <IonText slot="end" color="success" >
                            <h1>200</h1>
                        </IonText>
                        <IonText slot="end">
                            <IonText color="success">
                                +0.087
                            </IonText>
                            <br />
                            <IonText color="success">
                                -0.002
                            </IonText>
                        </IonText>
                    </IonItem>
                    <IonItem>
                        <IonButton fill="outline" slot="end">Keep</IonButton>
                    </IonItem>
                </IonCardContent>
            </IonCard>

            <IonCard>
                <IonItem>
                    <IonIcon icon={pin} slot="start" />
                    <IonLabel>ion-item in a card, icon left, button right</IonLabel>
                    <IonButton fill="outline" slot="end">View</IonButton>
                </IonItem>

                <IonCardContent>
                    This is content, without any paragraph or header tags,
                    within an ion-cardContent element.
                </IonCardContent>
            </IonCard>

            <IonCard>
                <IonItem href="#" className="ion-activated">
                    <IonIcon icon={wifi} slot="start" />
                    <IonLabel>Card Link Item 1 activated</IonLabel>
                </IonItem>

                <IonItem href="#">
                    <IonIcon icon={wine} slot="start" />
                    <IonLabel>Card Link Item 2</IonLabel>
                </IonItem>

                <IonItem className="ion-activated">
                    <IonIcon icon={warning} slot="start" />
                    <IonLabel>Card Button Item 1 activated</IonLabel>
                </IonItem>

                <IonItem>
                    <IonIcon icon={walk} slot="start" />
                    <IonLabel>Card Button Item 2</IonLabel>
                </IonItem>
            </IonCard>
            <IonCard>

            </IonCard>



        </div>
    );
};

export default Tollerance;

const toleranceData:any = {
    "holes": [
        {
            "standard": "E6",
            "1h": 28,
            "1l": 20,
            "2h": 34,
            "2l": 25,
            "3h": 43,
            "3l": 32,
            "4h": 53,
            "4l": 40,
            "5h": 66,
            "5l": 50,
            "6h": 79,
            "6l": 60,
            "7h": 94,
            "7l": 72,
            "8h": 110,
            "8l": 85,
            "9h": 129,
            "9l": 100,
            "10h": 142,
            "10l": 110,
            "11h": 161,
            "11l": 125
        },
        {
            "standard": "E7",
            "1h": 32,
            "1l": 20,
            "2h": 40,
            "2l": 25,
            "3h": 50,
            "3l": 32,
            "4h": 61,
            "4l": 40,
            "5h": 75,
            "5l": 50,
            "6h": 90,
            "6l": 60,
            "7h": 107,
            "7l": 72,
            "8h": 125,
            "8l": 85,
            "9h": 146,
            "9l": 100,
            "10h": 162,
            "10l": 110,
            "11h": 185,
            "11l": 125
        },
        {
            "standard": "E11",
            "1h": 95,
            "1l": 20,
            "2h": 115,
            "2l": 25,
            "3h": 142,
            "3l": 32,
            "4h": 170,
            "4l": 40,
            "5h": 210,
            "5l": 50,
            "6h": 250,
            "6l": 60,
            "7h": 292,
            "7l": 72,
            "8h": 335,
            "8l": 85,
            "9h": 390,
            "9l": 100,
            "10h": 430,
            "10l": 110,
            "11h": 485,
            "11l": 125
        },
        {
            "standard": "E12",
            "1h": 140,
            "1l": 20,
            "2h": 175,
            "2l": 25,
            "3h": 212,
            "3l": 32,
            "4h": 250,
            "4l": 40,
            "5h": 300,
            "5l": 50,
            "6h": 360,
            "6l": 60,
            "7h": 422,
            "7l": 72,
            "8h": 485,
            "8l": 85,
            "9h": 560,
            "9l": 100,
            "10h": 630,
            "10l": 110,
            "11h": 695,
            "11l": 125
        },
        {
            "standard": "E13",
            "1h": 200,
            "1l": 20,
            "2h": 245,
            "2l": 25,
            "3h": 302,
            "3l": 32,
            "4h": 370,
            "4l": 40,
            "5h": 440,
            "5l": 50,
            "6h": 520,
            "6l": 60,
            "7h": 612,
            "7l": 72,
            "8h": 715,
            "8l": 85,
            "9h": 820,
            "9l": 100,
            "10h": 920,
            "10l": 110,
            "11h": 1015,
            "11l": 125
        },
        {
            "standard": "F6",
            "1h": 18,
            "1l": 10,
            "2h": 22,
            "2l": 13,
            "3h": 27,
            "3l": 16,
            "4h": 33,
            "4l": 20,
            "5h": 41,
            "5l": 25,
            "6h": 49,
            "6l": 30,
            "7h": 58,
            "7l": 36,
            "8h": 68,
            "8l": 43,
            "9h": 79,
            "9l": 50,
            "10h": 88,
            "10l": 56,
            "11h": 98,
            "11l": 62
        },
        {
            "standard": "F7",
            "1h": 22,
            "1l": 10,
            "2h": 28,
            "2l": 13,
            "3h": 34,
            "3l": 16,
            "4h": 41,
            "4l": 20,
            "5h": 50,
            "5l": 25,
            "6h": 60,
            "6l": 30,
            "7h": 71,
            "7l": 36,
            "8h": 83,
            "8l": 43,
            "9h": 96,
            "9l": 50,
            "10h": 108,
            "10l": 56,
            "11h": 119,
            "11l": 62
        },
        {
            "standard": "F8",
            "1h": 28,
            "1l": 10,
            "2h": 35,
            "2l": 13,
            "3h": 43,
            "3l": 16,
            "4h": 53,
            "4l": 20,
            "5h": 64,
            "5l": 25,
            "6h": 76,
            "6l": 30,
            "7h": 90,
            "7l": 36,
            "8h": 106,
            "8l": 43,
            "9h": 122,
            "9l": 50,
            "10h": 137,
            "10l": 56,
            "11h": 151,
            "11l": 62
        },
        {
            "standard": "G6",
            "1h": 12,
            "1l": 4,
            "2h": 14,
            "2l": 5,
            "3h": 17,
            "3l": 6,
            "4h": 20,
            "4l": 7,
            "5h": 25,
            "5l": 9,
            "6h": 29,
            "6l": 10,
            "7h": 34,
            "7l": 12,
            "8h": 39,
            "8l": 14,
            "9h": 44,
            "9l": 15,
            "10h": 49,
            "10l": 17,
            "11h": 54,
            "11l": 18
        },
        {
            "standard": "G7",
            "1h": 16,
            "1l": 4,
            "2h": 20,
            "2l": 5,
            "3h": 24,
            "3l": 6,
            "4h": 28,
            "4l": 7,
            "5h": 34,
            "5l": 9,
            "6h": 40,
            "6l": 10,
            "7h": 47,
            "7l": 12,
            "8h": 54,
            "8l": 14,
            "9h": 61,
            "9l": 15,
            "10h": 69,
            "10l": 17,
            "11h": 75,
            "11l": 18
        },
        {
            "standard": "G8",
            "1h": 22,
            "1l": 4,
            "2h": 27,
            "2l": 5,
            "3h": 33,
            "3l": 6,
            "4h": 40,
            "4l": 7,
            "5h": 48,
            "5l": 9,
            "6h": 56,
            "6l": 10,
            "7h": 66,
            "7l": 12,
            "8h": 77,
            "8l": 14,
            "9h": 87,
            "9l": 15,
            "10h": 98,
            "10l": 17,
            "11h": 107,
            "11l": 18
        },
        {
            "standard": "H6",
            "1h": 8,
            "1l": 0,
            "2h": 9,
            "2l": 0,
            "3h": 11,
            "3l": 0,
            "4h": 13,
            "4l": 0,
            "5h": 16,
            "5l": 0,
            "6h": 19,
            "6l": 0,
            "7h": 22,
            "7l": 0,
            "8h": 25,
            "8l": 0,
            "9h": 29,
            "9l": 0,
            "10h": 32,
            "10l": 0,
            "11h": 36,
            "11l": 0
        },
        {
            "standard": "H7",
            "1h": 12,
            "1l": 0,
            "2h": 15,
            "2l": 0,
            "3h": 18,
            "3l": 0,
            "4h": 21,
            "4l": 0,
            "5h": 25,
            "5l": 0,
            "6h": 30,
            "6l": 0,
            "7h": 35,
            "7l": 0,
            "8h": 40,
            "8l": 0,
            "9h": 46,
            "9l": 0,
            "10h": 52,
            "10l": 0,
            "11h": 57,
            "11l": 0
        },
        {
            "standard": "H8",
            "1h": 18,
            "1l": 0,
            "2h": 22,
            "2l": 0,
            "3h": 27,
            "3l": 0,
            "4h": 33,
            "4l": 0,
            "5h": 39,
            "5l": 0,
            "6h": 46,
            "6l": 0,
            "7h": 54,
            "7l": 0,
            "8h": 63,
            "8l": 0,
            "9h": 72,
            "9l": 0,
            "10h": 81,
            "10l": 0,
            "11h": 89,
            "11l": 0
        },
        {
            "standard": "H9",
            "1h": 30,
            "1l": 0,
            "2h": 36,
            "2l": 0,
            "3h": 43,
            "3l": 0,
            "4h": 52,
            "4l": 0,
            "5h": 62,
            "5l": 0,
            "6h": 74,
            "6l": 0,
            "7h": 87,
            "7l": 0,
            "8h": 100,
            "8l": 0,
            "9h": 115,
            "9l": 0,
            "10h": 130,
            "10l": 0,
            "11h": 140,
            "11l": 0
        },
        {
            "standard": "H10",
            "1h": 48,
            "1l": 0,
            "2h": 58,
            "2l": 0,
            "3h": 70,
            "3l": 0,
            "4h": 84,
            "4l": 0,
            "5h": 100,
            "5l": 0,
            "6h": 120,
            "6l": 0,
            "7h": 140,
            "7l": 0,
            "8h": 160,
            "8l": 0,
            "9h": 185,
            "9l": 0,
            "10h": 210,
            "10l": 0,
            "11h": 230,
            "11l": 0
        },
        {
            "standard": "H11",
            "1h": 75,
            "1l": 0,
            "2h": 90,
            "2l": 0,
            "3h": 110,
            "3l": 0,
            "4h": 130,
            "4l": 0,
            "5h": 160,
            "5l": 0,
            "6h": 190,
            "6l": 0,
            "7h": 220,
            "7l": 0,
            "8h": 250,
            "8l": 0,
            "9h": 290,
            "9l": 0,
            "10h": 320,
            "10l": 0,
            "11h": 360,
            "11l": 0
        },
        {
            "standard": "J6",
            "1h": 5,
            "1l": -3,
            "2h": 5,
            "2l": -4,
            "3h": 6,
            "3l": -5,
            "4h": 8,
            "4l": -5,
            "5h": 10,
            "5l": -6,
            "6h": 13,
            "6l": -6,
            "7h": 16,
            "7l": 6,
            "8h": 18,
            "8l": -7,
            "9h": 22,
            "9l": -7,
            "10h": 25,
            "10l": -7,
            "11h": 29,
            "11l": -7
        },
        {
            "standard": "J7",
            "1h": 6,
            "1l": -6,
            "2h": 8,
            "2l": -7,
            "3h": 10,
            "3l": -8,
            "4h": 12,
            "4l": -9,
            "5h": 14,
            "5l": -11,
            "6h": 18,
            "6l": -12,
            "7h": 22,
            "7l": 13,
            "8h": 26,
            "8l": -14,
            "9h": 30,
            "9l": -16,
            "10h": 36,
            "10l": -16,
            "11h": 39,
            "11l": -18
        },
        {
            "standard": "J8",
            "1h": 10,
            "1l": -8,
            "2h": 12,
            "2l": -10,
            "3h": 15,
            "3l": -12,
            "4h": 20,
            "4l": -13,
            "5h": 24,
            "5l": -15,
            "6h": 28,
            "6l": -18,
            "7h": 34,
            "7l": 20,
            "8h": 41,
            "8l": -22,
            "9h": 47,
            "9l": -25,
            "10h": 55,
            "10l": -26,
            "11h": 60,
            "11l": -29
        }
    ],
    "shafts":  [
        {
            "standard": "E6",
            "1h": 28,
            "1l": 20,
            "2h": 34,
            "2l": 25,
            "3h": 43,
            "3l": 32,
            "4h": 53,
            "4l": 40,
            "5h": 66,
            "5l": 50,
            "6h": 79,
            "6l": 60,
            "7h": 94,
            "7l": 72,
            "8h": 110,
            "8l": 85,
            "9h": 129,
            "9l": 100,
            "10h": 142,
            "10l": 110,
            "11h": 161,
            "11l": 125
        },
        {
            "standard": "E7",
            "1h": 32,
            "1l": 20,
            "2h": 40,
            "2l": 25,
            "3h": 50,
            "3l": 32,
            "4h": 61,
            "4l": 40,
            "5h": 75,
            "5l": 50,
            "6h": 90,
            "6l": 60,
            "7h": 107,
            "7l": 72,
            "8h": 125,
            "8l": 85,
            "9h": 146,
            "9l": 100,
            "10h": 162,
            "10l": 110,
            "11h": 185,
            "11l": 125
        },
        {
            "standard": "E11",
            "1h": 95,
            "1l": 20,
            "2h": 115,
            "2l": 25,
            "3h": 142,
            "3l": 32,
            "4h": 170,
            "4l": 40,
            "5h": 210,
            "5l": 50,
            "6h": 250,
            "6l": 60,
            "7h": 292,
            "7l": 72,
            "8h": 335,
            "8l": 85,
            "9h": 390,
            "9l": 100,
            "10h": 430,
            "10l": 110,
            "11h": 485,
            "11l": 125
        },
        {
            "standard": "E12",
            "1h": 140,
            "1l": 20,
            "2h": 175,
            "2l": 25,
            "3h": 212,
            "3l": 32,
            "4h": 250,
            "4l": 40,
            "5h": 300,
            "5l": 50,
            "6h": 360,
            "6l": 60,
            "7h": 422,
            "7l": 72,
            "8h": 485,
            "8l": 85,
            "9h": 560,
            "9l": 100,
            "10h": 630,
            "10l": 110,
            "11h": 695,
            "11l": 125
        },
        {
            "standard": "E13",
            "1h": 200,
            "1l": 20,
            "2h": 245,
            "2l": 25,
            "3h": 302,
            "3l": 32,
            "4h": 370,
            "4l": 40,
            "5h": 440,
            "5l": 50,
            "6h": 520,
            "6l": 60,
            "7h": 612,
            "7l": 72,
            "8h": 715,
            "8l": 85,
            "9h": 820,
            "9l": 100,
            "10h": 920,
            "10l": 110,
            "11h": 1015,
            "11l": 125
        },
        {
            "standard": "F6",
            "1h": 18,
            "1l": 10,
            "2h": 22,
            "2l": 13,
            "3h": 27,
            "3l": 16,
            "4h": 33,
            "4l": 20,
            "5h": 41,
            "5l": 25,
            "6h": 49,
            "6l": 30,
            "7h": 58,
            "7l": 36,
            "8h": 68,
            "8l": 43,
            "9h": 79,
            "9l": 50,
            "10h": 88,
            "10l": 56,
            "11h": 98,
            "11l": 62
        },
        {
            "standard": "F7",
            "1h": 22,
            "1l": 10,
            "2h": 28,
            "2l": 13,
            "3h": 34,
            "3l": 16,
            "4h": 41,
            "4l": 20,
            "5h": 50,
            "5l": 25,
            "6h": 60,
            "6l": 30,
            "7h": 71,
            "7l": 36,
            "8h": 83,
            "8l": 43,
            "9h": 96,
            "9l": 50,
            "10h": 108,
            "10l": 56,
            "11h": 119,
            "11l": 62
        },
        {
            "standard": "F8",
            "1h": 28,
            "1l": 10,
            "2h": 35,
            "2l": 13,
            "3h": 43,
            "3l": 16,
            "4h": 53,
            "4l": 20,
            "5h": 64,
            "5l": 25,
            "6h": 76,
            "6l": 30,
            "7h": 90,
            "7l": 36,
            "8h": 106,
            "8l": 43,
            "9h": 122,
            "9l": 50,
            "10h": 137,
            "10l": 56,
            "11h": 151,
            "11l": 62
        },
        {
            "standard": "G6",
            "1h": 12,
            "1l": 4,
            "2h": 14,
            "2l": 5,
            "3h": 17,
            "3l": 6,
            "4h": 20,
            "4l": 7,
            "5h": 25,
            "5l": 9,
            "6h": 29,
            "6l": 10,
            "7h": 34,
            "7l": 12,
            "8h": 39,
            "8l": 14,
            "9h": 44,
            "9l": 15,
            "10h": 49,
            "10l": 17,
            "11h": 54,
            "11l": 18
        },
        {
            "standard": "G7",
            "1h": 16,
            "1l": 4,
            "2h": 20,
            "2l": 5,
            "3h": 24,
            "3l": 6,
            "4h": 28,
            "4l": 7,
            "5h": 34,
            "5l": 9,
            "6h": 40,
            "6l": 10,
            "7h": 47,
            "7l": 12,
            "8h": 54,
            "8l": 14,
            "9h": 61,
            "9l": 15,
            "10h": 69,
            "10l": 17,
            "11h": 75,
            "11l": 18
        },
        {
            "standard": "G8",
            "1h": 22,
            "1l": 4,
            "2h": 27,
            "2l": 5,
            "3h": 33,
            "3l": 6,
            "4h": 40,
            "4l": 7,
            "5h": 48,
            "5l": 9,
            "6h": 56,
            "6l": 10,
            "7h": 66,
            "7l": 12,
            "8h": 77,
            "8l": 14,
            "9h": 87,
            "9l": 15,
            "10h": 98,
            "10l": 17,
            "11h": 107,
            "11l": 18
        },
        {
            "standard": "H6",
            "1h": 8,
            "1l": 0,
            "2h": 9,
            "2l": 0,
            "3h": 11,
            "3l": 0,
            "4h": 13,
            "4l": 0,
            "5h": 16,
            "5l": 0,
            "6h": 19,
            "6l": 0,
            "7h": 22,
            "7l": 0,
            "8h": 25,
            "8l": 0,
            "9h": 29,
            "9l": 0,
            "10h": 32,
            "10l": 0,
            "11h": 36,
            "11l": 0
        },
        {
            "standard": "H7",
            "1h": 12,
            "1l": 0,
            "2h": 15,
            "2l": 0,
            "3h": 18,
            "3l": 0,
            "4h": 21,
            "4l": 0,
            "5h": 25,
            "5l": 0,
            "6h": 30,
            "6l": 0,
            "7h": 35,
            "7l": 0,
            "8h": 40,
            "8l": 0,
            "9h": 46,
            "9l": 0,
            "10h": 52,
            "10l": 0,
            "11h": 57,
            "11l": 0
        },
        {
            "standard": "H8",
            "1h": 18,
            "1l": 0,
            "2h": 22,
            "2l": 0,
            "3h": 27,
            "3l": 0,
            "4h": 33,
            "4l": 0,
            "5h": 39,
            "5l": 0,
            "6h": 46,
            "6l": 0,
            "7h": 54,
            "7l": 0,
            "8h": 63,
            "8l": 0,
            "9h": 72,
            "9l": 0,
            "10h": 81,
            "10l": 0,
            "11h": 89,
            "11l": 0
        },
        {
            "standard": "H9",
            "1h": 30,
            "1l": 0,
            "2h": 36,
            "2l": 0,
            "3h": 43,
            "3l": 0,
            "4h": 52,
            "4l": 0,
            "5h": 62,
            "5l": 0,
            "6h": 74,
            "6l": 0,
            "7h": 87,
            "7l": 0,
            "8h": 100,
            "8l": 0,
            "9h": 115,
            "9l": 0,
            "10h": 130,
            "10l": 0,
            "11h": 140,
            "11l": 0
        },
        {
            "standard": "H10",
            "1h": 48,
            "1l": 0,
            "2h": 58,
            "2l": 0,
            "3h": 70,
            "3l": 0,
            "4h": 84,
            "4l": 0,
            "5h": 100,
            "5l": 0,
            "6h": 120,
            "6l": 0,
            "7h": 140,
            "7l": 0,
            "8h": 160,
            "8l": 0,
            "9h": 185,
            "9l": 0,
            "10h": 210,
            "10l": 0,
            "11h": 230,
            "11l": 0
        },
        {
            "standard": "H11",
            "1h": 75,
            "1l": 0,
            "2h": 90,
            "2l": 0,
            "3h": 110,
            "3l": 0,
            "4h": 130,
            "4l": 0,
            "5h": 160,
            "5l": 0,
            "6h": 190,
            "6l": 0,
            "7h": 220,
            "7l": 0,
            "8h": 250,
            "8l": 0,
            "9h": 290,
            "9l": 0,
            "10h": 320,
            "10l": 0,
            "11h": 360,
            "11l": 0
        },
        {
            "standard": "J6",
            "1h": 5,
            "1l": -3,
            "2h": 5,
            "2l": -4,
            "3h": 6,
            "3l": -5,
            "4h": 8,
            "4l": -5,
            "5h": 10,
            "5l": -6,
            "6h": 13,
            "6l": -6,
            "7h": 16,
            "7l": 6,
            "8h": 18,
            "8l": -7,
            "9h": 22,
            "9l": -7,
            "10h": 25,
            "10l": -7,
            "11h": 29,
            "11l": -7
        },
        {
            "standard": "J7",
            "1h": 6,
            "1l": -6,
            "2h": 8,
            "2l": -7,
            "3h": 10,
            "3l": -8,
            "4h": 12,
            "4l": -9,
            "5h": 14,
            "5l": -11,
            "6h": 18,
            "6l": -12,
            "7h": 22,
            "7l": 13,
            "8h": 26,
            "8l": -14,
            "9h": 30,
            "9l": -16,
            "10h": 36,
            "10l": -16,
            "11h": 39,
            "11l": -18
        },
        {
            "standard": "J8",
            "1h": 10,
            "1l": -8,
            "2h": 12,
            "2l": -10,
            "3h": 15,
            "3l": -12,
            "4h": 20,
            "4l": -13,
            "5h": 24,
            "5l": -15,
            "6h": 28,
            "6l": -18,
            "7h": 34,
            "7l": 20,
            "8h": 41,
            "8l": -22,
            "9h": 47,
            "9l": -25,
            "10h": 55,
            "10l": -26,
            "11h": 60,
            "11l": -29
        }
    ]
}
